.App {
  font-family: sans-serif;
  text-align: center;
  margin-top: 20px;
}

.cribbage-board-container {
  position: relative;
  margin-bottom: 20px;
}

.cribbage-board-image {
  max-width: 100%;
  height: auto;
}

.main-content {
  padding: 4px 14px 24px 14px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.grid-container {
  margin-top: 20px;
}

.place-payout-table {
  width: 100%;
  border-collapse: collapse;
}

.place-payout-table th,
.place-payout-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.place-payout-table th {
  background-color: #6ea2ff; /* Header row color */
  color: #fff; /* Text color for header row */
}

.place-payout-table tbody tr:nth-child(even) {
  background-color: #f2f2f2; /* Alternate row color (light grey) */
}

.inputs {
  display: flex;
  align-content: left;
  flex-direction: column;
  text-align: left;

  /* label {
    padding: 5px;
  }
  input {
    width: 30px;
    font-size: 16px;
  } */
}

.presets {
  display: flex;
  align-content: left;
  text-align: left;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.totalAmounts {
  display: flex;
  align-content: left;
  flex-direction: column;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  div {
    padding: 2px;
  }
}

.logo {
  margin-bottom: 4px;
}

.switchFormGroup {
  margin: 4px;
  padding: 2px;
  border: 1px solid rgb(0, 0, 0, 0.23);
  border-radius: 4px;
  label {
    margin-left: 4px;
    span.MuiFormControlLabel-label {
      font-size: 12px;
    }
  }
}
